<template>
  <div>
    <v-text-field
        v-model="formGenerateToken.cc_number"
        name="cc_number"
        label="クレジットカード番号"
        required
        prepend-icon="mdi-pencil"
        :error-messages="formError.cc_number"
        maxlength="16"
        inputmode="numeric"
        pattern="\d+"
        class="mt-2 mb-2"
    />

    <v-divider></v-divider>

    <v-text-field
        v-model="formGenerateToken.cc_expiration"
        name="cc_expiration"
        label="クレジットカード有効期限"
        required
        prepend-icon="mdi-pencil"
        :error-messages="formError.cc_expiration"
        maxlength="6"
        hint="西暦と月をつなげた6桁の数字(YYYYMM)"
        inputmode="numeric"
        pattern="20\d\d[01][0-9]"
        persistent-hint
        class="mt-2 mb-2"
    />

    <v-divider></v-divider>

    <v-text-field
        v-model="formGenerateToken.security_code"
        name="security_code"
        label="セキュリティコード"
        required
        prepend-icon="mdi-pencil"
        :error-messages="formError.security_code"
        maxlength="4"
        hint="カード裏面または表面に記載された3桁もしくは4桁の番号"
        inputmode="numeric"
        pattern="\d+"
        persistent-hint
        class="mt-2 mb-2"
    />

    <v-dialog
        v-model="dialog"
        width="450px"
    >
      <v-card>
        <v-card-title class="headline">クレジットカード決済</v-card-title>

        <v-card-text v-if="confirmMessages">
          以下の内容で決済を行います。
        </v-card-text>

        <v-card-text v-if="confirmMessages">
          <ul>
            <li v-for="(confirmMessage, i) in confirmMessages" :key="i">
              {{ confirmMessage }}
            </li>
          </ul>
        </v-card-text>

        <!-- プリンタ有り駐輪場の場合のみQRコード警告を表示 -->
        <template v-if="hasPrinter">
          <v-card-text>
            QRコードを読み取れない場合がございます。
          </v-card-text>

          <v-card-text>
            <ul>
              <li>携帯の画面にひび割れはございませんか？</li>
              <li>画面保護フィルムの汚れ、傷はありませんか？</li>
              <li>画面の明るさの設定が暗くなってませんか？</li>
            </ul>
          </v-card-text>

          <v-card-text>
            ※QRコードが読み取れない場合は印刷してご利用いただくかコールセンターまたはお問い合わせ欄よりご連絡ください。
          </v-card-text>
        </template>

        <v-form>
          <form class="form" @submit.prevent="apply()">
            <v-card-text class="text-center">
              <v-row>
                <v-spacer></v-spacer>

                <v-checkbox
                    v-model="dialogAgree"
                    name="submit-confirm"
                    label="上記内容を確認しました"
                    required
                >
                </v-checkbox>
                <v-spacer></v-spacer>
              </v-row>

              <Loading v-if="loading"></Loading>
              <v-row v-else>
                <v-spacer></v-spacer>
                <v-btn
                    type="submit"
                    class="mt-2"
                    dark depressed
                    color="primary"
                >
                  決済を実行する
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    @click="dialog = false; dialogAgree = false"
                    name="applyApi"
                    class="mt-2"
                    dark depressed
                    color="secondary"
                >
                  戻る
                </v-btn>
                <v-spacer></v-spacer>
              </v-row>
            </v-card-text>
          </form>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: [
    'config',
    'form',
    'formError',
    'confirmMessages',
    'hasPrinter',
    'paymentAction',
  ],
  data() {
    return {
      formGenerateToken: {},
      dialog: false,
      dialogAgree: false,
      loading: false,
    }
  },
  computed: {
    // 本番環境かどうか
    isProduction: function () {
      return !!this.config && (this.config.app.env === 'production');
    },
  },
  mounted() {
    // クレジットカード決済用ワンタイムトークン取得スクリプト
    let sbpsSystemTokenScript = document.createElement('script');
    sbpsSystemTokenScript.setAttribute('src', 'https://stbtoken.sps-system.com/sbpstoken/com_sbps_system_token.js');
    document.head.appendChild(sbpsSystemTokenScript);

    // 本番環境でなければダミーのクレジットカード情報をフォームに設定
    if (!this.isProduction) {
      this.$set(this.formGenerateToken, "cc_number", this.config.const.payment.sbps.dummy_cc_number);
      this.$set(this.formGenerateToken, "cc_expiration", this.config.const.payment.sbps.dummy_cc_expiration);
      this.$set(this.formGenerateToken, "security_code", this.config.const.payment.sbps.dummy_security_code);
    }
  },
  methods: {
    /**
     * 決済実行
     *
     * @return {Promise<void>}
     */
    async apply() {
      // ローディング表示
      this.loading = true;

      // ワンタイムトークン生成スクリプトに送信するパラメータ
      const params = {
        merchantId: this.config.const.payment.sbps.merchant_id,
        serviceId: this.config.const.payment.sbps.service_id,
        ccNumber: this.formGenerateToken.cc_number,
        ccExpiration: this.formGenerateToken.cc_expiration,
        securityCode: this.formGenerateToken.security_code,
      };

      // トークン生成ロジック呼び出し
      com_sbps_system.generateToken( // eslint-disable-line
          params,
          async (responseGenerateToken) => {
            // フォームに値を追加
            this.form["payment_action"] = this.paymentAction;
            this.form["generate_token"] = responseGenerateToken;

            // API URL
            let url = "";
            if (this.paymentAction === "parking_first") {
              url = '/api/sbps/apply';
            } else if (this.paymentAction === "parking_payment_change") {
              url = '/api/sbps/apply-payment-change/' + this.form["user_parking_zone_id"]
            } else if (this.paymentAction === "label_reissue") {
              url = '/api/sbps/apply-label-reissue/' + this.form["user_parking_contract_id"]
            }

            // バイク駐輪場の必要書類をオブジェクトにまとめる
            let documents = [];
            for (let key of ['image_license_front', 'image_license_back', 'image_vehicle_registration', 'image_insurance', 'image_vehicle', 'image_number_plate']) {
              if (this.form[key]) {
                documents[key] = this.form[key];
              }
            }

            // 書類が存在すればアップロードAPIを実行
            if (Object.keys(documents).length > 0) {
              let formData = new FormData();

              for (const [key, value] of Object.entries(documents)) {
                formData.append(key, value);
              }

              try {
                await this.$axios.post('/api/user/parking-required-documents', formData)
                    .then(async response => {
                      // ユニークキーをフォームに追加
                      this.form['user_parking_required_document_unique_key'] = response.data["user_parking_required_document"]["unique_key"];
                    })
                    .catch(error => {
                      // 決済失敗処理
                      this.apiError(error);

                      // 例外を投げる
                      throw new Error();
                    });
              } catch (err) {
                // 処理に失敗していればここで終了
                return true;
              }
            }

            // 学割プランの学生証をオブジェクトにまとめる
            let studentCardDocuments = [];
            for (let key of ['image_student_card_front', 'image_student_card_back']) {
              if (this.form[key]) {
                studentCardDocuments[key] = this.form[key];
              }
            }

            // 学生証が存在すればアップロードAPIを実行
            if (Object.keys(studentCardDocuments).length > 0) {
              let formData = new FormData();

              for (const [key, value] of Object.entries(studentCardDocuments)) {
                formData.append(key, value);
              }

              try {
                await this.$axios.post('/api/user/parking-student-cards', formData)
                    .then(async response => {
                      // ユニークキーをフォームに追加
                      this.form['user_parking_student_card_unique_key'] = response.data["user_parking_student_card"]["unique_key"];
                    })
                    .catch(error => {
                      // 決済失敗処理
                      this.apiError(error);

                      // 例外を投げる
                      throw new Error();
                    });
              } catch (err) {
                // 処理に失敗していればここで終了
                return true;
              }
            }

            // 決済実行
            await this.$axios.post(url, this.form)
                .then(response => {
                  // 決済成功

                  // ダイアログを閉じる
                  this.closeDialog();

                  // プリンタの有る駐輪エリアの場合
                  if (this.hasPrinter) {
                    this.$toast(
                        this.paymentAction === "label_reissue"
                            ? "新しいQRコードを発行しました。読み取り機にかざしてシールを印刷してください。"
                            : "申し込みを完了しました。QRコードを読み取り機にかざしてシールを印刷してください。",
                        {
                          timeout: 6000,
                        }
                    );

                    if (this.paymentAction === "label_reissue") {
                      // シール再発行であれば契約IDを指定してQRコードページに移動
                      this.$router.push({
                        name: 'UserParkingQrcodeWithContractId', params: {
                          userParkingZoneId: String(response.data.user_parking_zone_id),
                          userParkingContractId: this.form["user_parking_contract_id"],
                          labelReissueCount: String(response.data.label_reissue_count), // ルート変更をwatchしてQRコードデータを再読込するために付与
                        }
                      })
                    } else {
                      // 新規申し込みであればQRコードページに移動
                      this.$router.push({name: 'UserParkingQrcode', params: {userParkingZoneId: String(response.data.id)}})
                    }
                  }

                  // プリンタの無い駐輪エリアの場合
                  else {
                    this.$toast(
                        this.paymentAction === "label_reissue"
                            ? "新しいQRコードを発行しました。読み取り機にかざしてシールを印刷してください。"
                            : "申し込みを完了しました。ご利用の駐輪場情報をご確認ください。利用開始日以降に所定の位置に駐輪できます。",
                        {
                          timeout: 6000,
                        }
                    );

                    // 利用駐輪場ページに移動
                    this.$router.push({name:'UserParkingShow', params: {id: String(response.data.id)}})
                  }
                })
                .catch(error => {
                  // 決済失敗処理
                  this.apiError(error);
                });
          });
    },

    /**
     * ダイアログを開く
     *
     * @return {Promise<void>}
     */
    openDialog() {
      this.dialog = true;
    },

    /**
     * ダイアログを閉じる
     *
     * @return {Promise<void>}
     */
    closeDialog() {
      // ローディング解除
      this.loading = false;

      // 確認ダイアログを閉じる
      this.dialog = false
    },

    /**
     * 決済失敗処理
     */
    async apiError(error) {
      // 決済失敗

      // ダイアログを閉じる
      await this.closeDialog();

      if (error.response?.status === 422) {
        // バリデーションエラーであれば
        if (error.response.data.errors) {
          // 親コンポーネントにもエラーメッセージをセット
          await this.$emit('update:formError', error.response.data.errors)

          // 対応するinputが存在しない値のエラーをtoastで表示
          for (const [key, value] of Object.entries(this.formError)) {
            if (["parking_zone_id", "user_parking_required_document_unique_key", "parking_waiting_unique_key"].includes(key)) {
              value.forEach(value => this.$toast(value, {color: "error"}));
            }
          }
        } else if (error.response.data.message) {
          // エラーメッセージが含まれていなければmessageをそのまま表示する
          this.$toast(error.response.data.message, {color: "error"})
        }
      } else {
        this.$store.state.error.response = error.response;
      }
    }
  },
}
</script>
