import Vue from 'vue'

// ほかモジュールより先にSentryをインポート
import * as Sentry from "@sentry/vue";
Vue.prototype.$sentry = Sentry; // グローバルに設定
import {Integrations} from "@sentry/tracing";

// 環境変数にdnsがあればSentryを設定
if (process.env.VUE_APP_SENTRY_DSN) {
    Sentry.init({
        Vue,
        dsn: process.env.VUE_APP_SENTRY_DSN,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0, // We recommend adjusting this value in production, or using tracesSampler for finer control
        logErrors: true,
    });
}

import axios from 'axios'
import VueAxios from 'vue-axios'
import axiosCancel from 'axios-cancel';
import vuetify from './plugins/vuetify';
import * as GmapVue from 'gmap-vue';
import VueQrcode from '@chenfengyuan/vue-qrcode';

import router from './router'
import store from './store'

import App from './App.vue'

/**
 * プラグイン設定
 */

// axios
Vue.use(VueAxios, axios);
Vue.prototype.$axios = axios;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.baseURL = process.env.BASE_URL ? process.env.BASE_URL : '';

// axios-cancel
axiosCancel(axios, {
    debug: false // default
});

// GmapVue
Vue.use(GmapVue, {
    load: {
        // key: process.env.VUE_APP_GOOGLE_MAP_API,
        key: 'AIzaSyB1iweD0YscTSTwjwWKIn4Ty25PGEQB1gY',
        libraries: 'places',
        region: 'JP',
        language: 'ja'
    }
});

// vue-qrcode
Vue.component(VueQrcode.name, VueQrcode);

// dayjs
import dayjs from 'dayjs';
import 'dayjs/locale/ja';

dayjs.locale('ja');
Vue.prototype.$dayjs = dayjs;

Vue.config.productionTip = false

// グローバルコンポーネントを登録
import BackButton from './components/BackButton';
import Loading from './components/Loading';
import PageNavigation from './components/PageNavigation';
import PrivacyPolicy from "@/components/PrivacyPolicy";
import Sbps from "./components/Sbps";
import SbpsFormLabelReissue from "./components/SbpsFormLabelReissue";
import SbpsGenerateToken from "./components/SbpsGenerateToken";
import SbpsTestPaymentNotice from "./components/SbpsTestPaymentNotice";
import UserPolicy from "@/components/UserPolicy";

Vue.component('BackButton', BackButton);
Vue.component('Loading', Loading);
Vue.component('PageNavigation', PageNavigation);
Vue.component('PrivacyPolicy', PrivacyPolicy);
Vue.component('Sbps', Sbps);
Vue.component('SbpsFormLabelReissue', SbpsFormLabelReissue);
Vue.component('SbpsGenerateToken', SbpsGenerateToken);
Vue.component('SbpsTestPaymentNotice', SbpsTestPaymentNotice);
Vue.component('UserPolicy', UserPolicy);

const createApp = async () => {
    // ロード時にログイン中であるかの初回確認を行う
    await store.dispatch('auth/currentUser');

    // Vueオブジェクト生成
    new Vue({
        base: process.env.BASE_URL ? process.env.BASE_URL : '',
        router,
        store,
        vuetify,
        render: h => h(App)
    }).$mount('#app')
}

createApp();