import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Index',
        component: () => import('../pages/Index.vue'),
        meta: {
            title: "トップページ",
            isPublic: true,
        },
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('../pages/Register.vue'),
        meta: {
            title: "利用者登録",
            isPublic: true,
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../pages/Login.vue'),
        meta: {
            title: "ログイン",
            isPublic: true,
        },
        // ログイン中のログインフォームへのアクセスを防ぐナビゲーションガード
        beforeEnter(to, from, next) {
            if (store.getters['auth/check']) {
                // 遷移元がパラメータに設定されている場合は元ページに戻る
                next(to.query.from || '/');
            } else {
                next();
            }
        }
    },
    {
        path: '/user',
        name: 'UserIndex',
        component: () => import('../pages/user/Index.vue'),
        meta: {
            title: "マイページ",
        },
    },
    {
        path: '/user/parking/:id',
        name: 'UserParkingShow',
        component: () => import('../pages/user/ParkingShow.vue'),
        meta: {
            title: "利用中の駐輪場",
        },
    },
    // オフライン決済用の手動更新ページ
    {
        path: '/user/parking/:userParkingZoneId/contract-renewal',
        name: 'UserParkingContractRenewal',
        component: () => import('../pages/user/ParkingContractRenewal.vue'),
        meta: {
            title: "更新手続き",
        },
    },
    {
        path: '/user/parking/:userParkingZoneId/contract',
        name: 'UserParkingContract',
        component: () => import('../pages/user/ParkingContract.vue'),
        meta: {
            title: "お支払い方法の設定",
        },
    },
    {
        path: '/user/parking/:userParkingZoneId/qrcode',
        name: 'UserParkingQrcode',
        component: () => import('../pages/user/ParkingQrcode.vue'),
        meta: {
            title: "シール印刷用QRコード",
        },
    },
    {
        // 契約IDを指定して複数のQRコードの中から特定のコードを初期表示させる
        path: '/user/parking/:userParkingZoneId/qrcode/:userParkingContractId/:labelReissueCount',
        name: 'UserParkingQrcodeWithContractId',
        component: () => import('../pages/user/ParkingQrcode.vue'),
        meta: {
            title: "シール印刷用QRコード",
        },
    },
    {
        path: '/user/edit',
        name: 'UserEdit',
        component: () => import('../pages/user/Edit.vue'),
        meta: {
            title: "登録情報",
        },
    },
    {
        // 署名付きAPIへのアクセスを行い登録画面に移動するページ
        path: '/user/update-email',
        name: 'UpdateEmail',
        component: () => import('../pages/user/UpdateEmail.vue'),
        meta: {
            title: "メールアドレス更新",
        },
    },
    {
        // 署名付きAPIへのアクセスを行いパスワード変更画面に移動するページ
        path: '/password-reset',
        name: 'PasswordReset',
        component: () => import('../pages/PasswordReset.vue'),
        meta: {
            isPublic: true,
            title: "パスワード変更",
        },
    },
    {
        path: '/map',
        name: 'Map',
        component: () => import('../pages/Map.vue'),
        meta: {
            title: "現在位置から検索",
        },
    },
    {

        path: '/parking',
        name: 'ParkingIndex',
        component: () => import('../pages/parking/Index.vue'),
        meta: {
            isPublic: true,
            title: "駐輪場を探す",
        },
    },
    {
        path: '/parking/:id',
        name: 'ParkingShow',
        component: () => import('../pages/parking/Show.vue'),
        meta: {
            isPublic: true,
            title: "駐輪場詳細",
        },
    },
    {
        path: '/parking/:parkingId/:parkingZoneId/apply',
        name: 'ParkingApply',
        component: () => import('../pages/parking/Apply.vue'),
        meta: {
            title: "駐輪場申し込み",
        },
    },
    {
        path: '/parking/:parkingId/:parkingZoneId/apply/:parkingWaitingId/:parkingWaitingUniqueKey',
        name: 'ParkingApplyFromWaitings',
        component: () => import('../pages/parking/Apply.vue'),
        meta: {
            title: "駐輪場申し込み(キャンセル待ち)",
        },
    },
    {
        path: '/manual',
        name: 'Manual',
        component: () => import('../pages/Manual.vue'),
        meta: {
            title: "ご利用案内",
            isPublic: true,
        },
    },
    {
        path: '/faq',
        name: 'Faq',
        component: () => import('../pages/Faq.vue'),
        meta: {
            title: "よくあるご質問",
            isPublic: true,
        },
    },
    {
        path: '/contact',
        name: 'Contact',
        component: () => import('../pages/Contact.vue'),
        meta: {
            title: "お問い合わせ",
            isPublic: true,
        },
    },
    {
        path: '/commercial-transactions',
        name: 'CommercialTransactions',
        component: () => import('../pages/CommercialTransactions.vue'),
        meta: {
            title: "特定商取引法に基づく表記",
            isPublic: true,
        },
    },
    {
        path: '/policy',
        name: 'Policy',
        component: () => import('../pages/Policy.vue'),
        meta: {
            title: "個人情報の取り扱いについて",
            isPublic: true,
        },
    },
    {
        path: '/maintenance',
        name: 'Maintenance',
        component: () => import('../pages/Maintenance.vue'),
        meta: {
            title: "メンテナンス",
            isPublic: true,
        },
    },
    {
        path: '/tool/qrcode',
        name: 'ToolQrcode',
        component: () => import('../pages/tool/Qrcode.vue'),
        meta: {
            title: "QRコード出力",
            isPublic: true,
        },
    },
    {
        path: '/tool/sentry-test-send',
        name: 'SentryTestSend',
        component: () => import('../pages/tool/SentryTestSend.vue'),
        meta: {
            title: "Sentry Error Tracking Test",
            isPublic: true,
        },
    },
    {
        path: '*',
        name: 'NotFound',
        component: () => import('../pages/NotFound.vue'),
        meta: {
            title: "ページが見つかりません",
            isPublic: true,
        },
    },
];

const router = new VueRouter({
    mode: 'history',
    // 移動時にスクロール位置をリセットする
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {x: 0, y: 0}
        }
    },
    base: process.env.BASE_URL,
    routes
});

// グローバルナビゲーションガード
router.beforeEach((to, from, next) => {
    // トップページへのアクセスでログインしていればマイページに移動
    if (to.name === "Index" && store.getters['auth/check']) {
        next({
            path: '/user'
        });
        return;
    }

    // ログインを要求するルートが含まれ、ログインしていなければログインページに移動
    if (to.matched.some(record => !record.meta.isPublic) && !store.getters['auth/check']) {
        next({
            path: '/login',
            query: {to: to.fullPath, from: from.fullPath}
        });
    } else {
        next();
    }
});

// タイトルの書き換え
router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = "CYCLE PLAZA | " + (to.meta.title || to.path);
    });
});

export default router
